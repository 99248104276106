import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  Type,
  ViewEncapsulation,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { GuidUtils } from '../../../core/utils/guid.utils';
import { GeoPoint } from '@freddy/common';
import * as mapboxGl from 'mapbox-gl';
import { Map, MapMouseEvent, Marker, NavigationControl } from 'mapbox-gl';
import { circle, Units } from '@turf/turf';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { Result } from '@mapbox/mapbox-gl-geocoder';
import { environment } from '../../../../environments/environment';
import { GeoUtils } from '../../../core/utils/geo.utils';
import { SatelliteToggleControl } from './controls/sattelite-toggle-control';

export interface MapLine {
  coordinates: GeoPoint[];
  color: string;
  width: number;
}

export interface MapCircle {
  center: GeoPoint;
  radiusInMeters: number;
  color: string;
  opacity: number;
  fill: boolean;
}

export interface MapMarker {
  title?: string;
  titleClass?: string;
  id?: string;
  coordinate: GeoPoint;
  markerClass?: string;
  icon?: string;
  click?: () => void;
  component?: Type<any>;
  draggable?: boolean;
  params?: Record<string, any>;
}

export interface MapLegend {
  label: string;
  markerClass: string;
  icon: string;
}

export interface MarkerDragEndEvent {
  marker: MapMarker;
  coordinate: GeoPoint;
}

export interface GeoCoderResult {
  name: string;
  center: GeoPoint;
  city?: string;
  geoHash: string;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnChanges {
  constructor() {}

  guidUtil = GuidUtils;
  ready$ = new ReplaySubject<boolean>(1);
  map: Map | undefined;
  private isEmittingAllowed = true;

  @Input()
  lines: MapLine[] | null = [];

  @Input()
  circles: MapCircle[] | null = [];

  @Input()
  markers: MapMarker[] | null = [];

  @Input()
  legends: MapLegend[] | null = [];

  @Input()
  center: GeoPoint | undefined;

  @Input()
  geoCoderInput: string | undefined;

  @Input()
  geoCoderActivated = false;

  @Output()
  markerClicked = new EventEmitter<MapMarker>();

  @Output()
  mapClicked = new EventEmitter<MapMouseEvent>();

  @Output()
  markerDragEnd = new EventEmitter<MarkerDragEndEvent>();
  @Output()
  geoCoderResult = new EventEmitter<GeoCoderResult>();
  private geocoder: MapboxGeocoder | undefined;

  getCoordinates(geoPoints: GeoPoint[]): number[][] {
    return geoPoints.map((g) => [g.lon, g.lat]);
  }

  getCoordinate(geoPoint: GeoPoint | null): number[] {
    return geoPoint ? [geoPoint.lon, geoPoint.lat] : [0, 0];
  }

  onMarkerClick($event: any, marker: MapMarker): void {
    $event.stopPropagation();
    if (marker.click) {
      marker.click();
    }
    this.markerClicked.next(marker);
  }

  mapLoaded() {
    this.map?.on('click', (event: MapMouseEvent) => {
      this.mapClicked.next(event);
    });
    this.ready$.next(true);
    this.map?.resize();
    if (this.geoCoderActivated && this.geocoder) {
      this.map?.addControl(this.geocoder);
      const geoCoderInput = (this.geocoder as any)._inputEl;
      if (geoCoderInput) {
        geoCoderInput.focus();
      }
    }
    this.map?.addControl(new SatelliteToggleControl(), 'top-right');
    this.map?.addControl(new NavigationControl());
  }

  onMarkerDragEnd(marker: MapMarker, $event: Marker) {
    $event.getElement().addEventListener('click', function (e) {
      e.stopPropagation();
    });
    if (this.isEmittingAllowed) {
      this.isEmittingAllowed = false;

      this.markerDragEnd.next({
        marker,
        coordinate: {
          lat: $event.getLngLat().lat,
          lon: $event.getLngLat().lng,
        },
      });

      setTimeout(() => {
        this.isEmittingAllowed = true;
      }, 100);
    }
  }

  createCircle(center: GeoPoint, radiusInMeters: number) {
    const options = { steps: 64, units: 'meters' as Units };
    return circle([center.lon, center.lat], radiusInMeters, options);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['geoCoderActivated']?.currentValue) {
      this.geocoder = new MapboxGeocoder({
        accessToken: environment.mapbox.token,
        mapboxgl: mapboxGl,
        zoom: 12,
        countries: environment.mapbox.geocoder.countriesActivated,
      });

      this.geocoder.on('result', (event: { result: Result }) => {
        const center: GeoPoint = {
          lon: event.result.center[0],
          lat: event.result.center[1],
        };

        const geoCoderResult: GeoCoderResult = {
          name: event.result.place_name,
          center: center,
          city: this.getLocality(event.result),
          geoHash: GeoUtils.getGeoHashForLocation(center),
        };
        this.geoCoderResult.emit(geoCoderResult);
      });
    }
  }

  private getLocality(result: MapboxGeocoder.Result): string | undefined {
    const possibleLocalityKey = ['locality', 'place', 'region'];
    for (const localityKey of possibleLocalityKey) {
      const locality = result.context.find(
        (prop) => prop?.id?.indexOf(localityKey) !== -1,
      )?.text;
      if (locality) {
        return locality;
      }
    }
    return;
  }
}
