export const environment = {
  name: 'prod',
  version: '%%TAG_VERSION%%',
  production: true,
  useEmulators: false,
  sentry: {
    dsn: 'https://a9aa9d80e988394f6501db85fa2c9d55@o4506207716114432.ingest.us.sentry.io/4507981510606848',
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    showDialog: false,
  },
  firebase: {
    region: 'europe-west1',
    apiKey: 'AIzaSyDrVsDjetv4CAtOGesgTFV5C7UZXL0SzM8',
    authDomain: 'admin.freddy.ovh',
    databaseURL:
      'https://freddy-prod-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'freddy-prod',
    storageBucket: 'freddy-prod.appspot.com',
    messagingSenderId: '726871418891',
    appId: '1:726871418891:web:8b2b60d2d2c4c3db5b3f0e',
    measurementId: 'G-2VDQM54B55',
    recaptcha: '6Ld7jxYqAAAAACuU6wfebxko4byvpqANYJ9Vx9jL',
  },
  mapbox: {
    token:
      'pk.eyJ1IjoiYm9rem9yIiwiYSI6ImNseXloZ3FobzI4djcycXNoYWdhajA0dngifQ.PgA8mAMfTXoI4zYrNVXrQw',
    geocoder: {
      countriesActivated: 'fr,nl,be,de',
    },
  },
  typesense: {
    apiKey: 'freddy-admin',
    host: 'search.freddy.ovh',
    port: 443,
    protocol: 'https',
    scenariosCollectionName: 'scenarios-prod',
    gamesCollectionName: 'games-prod',
  },
  game: {
    defaultRadius: 15,
    defaultPoints: 10,
  },
};
